import React from "react";

import HeroSearch from "cre-plugin/src/components/search";
import { Container } from "../components";

import { Styled404, StyledPage } from "../components/css";
import { theme } from "../components/css/theme";

const PageNotFound = () => (

  <Container page="content">

    <div style={{ background: theme.primaryLight }}>

      <StyledPage>

        <div>

          <HeroSearch type="hero" state={{
            searchState: {
              sortBy: "ToLet",
              page: 1,
              range: {
                max_gla: {
                  min: 0,
                  max: 10000000000
                },
                min_gla: {
                  min: 0,
                  max: 10000000000
                }
              },
              refinementList: {
                property_category: [],
                suburb_cluster: []
              }
            }
          }} />

        </div>

      </StyledPage>

    </div>
  </Container>
)

export default PageNotFound